var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{staticClass:"\n      p-1\n    ",attrs:{"variant":"warning","show":""}},[_c('p',[_vm._v(" Apenas usuários que não estão em algum Kanban serão atribuídos. ")])]),_c('validation-observer',{ref:"rulesAssignTo"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Atribuir a","label-for":"assign_to"}},[_c('b-form-select',{attrs:{"id":"assign_to","options":_vm.selectUserRoles,"state":errors.length > 0 ? false:null},model:{value:(_vm.assignTo),callback:function ($$v) {_vm.assignTo=$$v},expression:"assignTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Consultor","label-for":"manager"}},[_c('b-form-select',{attrs:{"id":"manager","options":_vm.selectedRoleManagersOptions},model:{value:(_vm.manager),callback:function ($$v) {_vm.manager=$$v},expression:"manager"}})],1),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.force),callback:function ($$v) {_vm.force=$$v},expression:"force"}},[_vm._v(" Forçar atribuição ")]),_c('b-list-group',{staticClass:"mt-1"},[_c('b-list-group-item',{attrs:{"active":""}},[_vm._v(" Usuários: ")]),_vm._l((_vm.data),function(user){return _c('b-list-group-item',{key:user.id,staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" "+_vm._s(user.name)+" ("+_vm._s(user.id)+") ")])})],2)],1),(_vm.assignTo === 'success')?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Origem","label-for":"success_subscriber_origin"}},[_c('b-form-select',{attrs:{"id":"success_subscriber_origin","options":_vm.roleSuccessSubscriberOrigins,"state":errors.length > 0 ? false:null},model:{value:(_vm.successSubscriberOrigin),callback:function ($$v) {_vm.successSubscriberOrigin=$$v},expression:"successSubscriberOrigin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2937578784)}),_c('validation-provider',{attrs:{"rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","rows":"6","state":errors.length > 0 ? false:null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3057304006)})],1):_vm._e(),(_vm.assignTo === 'sales')?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Origem do Lead","label-for":"sales_lead_origin"}},[_c('b-form-select',{attrs:{"id":"sales_lead_origin","options":_vm.salesLeadOriginOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.salesLeadOrigin),callback:function ($$v) {_vm.salesLeadOrigin=$$v},expression:"salesLeadOrigin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4126456944)}),_c('validation-provider',{attrs:{"rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","rows":"6","state":errors.length > 0 ? false:null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3057304006)})],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"text-right mt-1"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":_vm.handleActionAssignTo}},[(_vm.multipleData)?_c('span',[_vm._v(" Atribuir "),_c('strong',[_vm._v(" "+_vm._s(_vm.data.length)+" ")]),_vm._v(" usuários ")]):_c('span',[_vm._v(" Atribuir usuário ")])])],1)],1)],1)],1),_c('b-modal',{ref:"assign-kanban-modal-force",attrs:{"hide-footer":"","size":"lg","title":"Forçar atribuição no Kanban","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":""}},[_vm._v(" Os usuários a seguir não foram atribuídos: "),_c('b-list-group',{staticClass:"mt-1"},_vm._l((_vm.usersNotAssigned),function(user){return _c('b-list-group-item',{key:user.id,staticClass:"d-flex justify-content-between align-items-center"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(user.name)+" ("+_vm._s(user.id)+") ")]),_c('b-col',{attrs:{"cols":"9"}},[_c('b-badge',{attrs:{"variant":"primary","pill":""}},[_vm._v(" "+_vm._s(_vm.$t(("roles." + (user.kanban.role))))+"->"+_vm._s(_vm.$t(("kanban.column." + (user.kanban.column))))+" ")]),_c('small',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(user.reason.split('\n').join('<BR>'))}})],1)],1)],1)}),1),_c('b-row',[_c('b-col',{staticClass:"text-right mt-1"},[_c('b-button',{staticClass:"mr-1",on:{"click":function($event){return _vm.forceAssignTo()}}},[_vm._v(" Forçar atribuição ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.hide()}}},[_vm._v(" Ok ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }