<template>
  <section
    id="chat-follow"
    ref="chatFollow"
  >
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="5">
              <b-form-group
                label="Arquivo CSV (exportado do SalesIQ)"
                label-for="name"
              >
                <b-form-file
                  id="file"
                  ref="file"
                  v-model="file"
                />
              </b-form-group>

              <b-form-group
                :label="`Processar a partir do ID (último ID processado: ${lastVisitorId})`"
              >
                <b-form-input
                  v-model="processVisitorIdAfter"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="7"
              class="form-buttons text-right d-flex justify-content-end flex-wrap align-content-end"
            >
              <b-form-group
                label=""
                label-for="btn-processar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-processar"
                  :disabled="isLoading"
                  variant="primary"
                  @click="getUsers"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Enviar arquivo
                </b-button>

                <b-button
                  id="btn-limpar"
                  :disabled="isLoading"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmitted">
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                responsive
                :items="results"
                :fields="result_fields"
                show-empty
                sort-icon-left
                no-local-sorting
                primary-key="visitor_id"
              >
                <template #empty>
                  <h4 class="text-center mt-2 mb-2">
                    Nenhum resultado encontrado
                  </h4>
                </template>

                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(selected)>
                  <div class="text-nowrap">
                    <b-form-checkbox
                      :checked="false"
                      @change="toggleAll"
                    />
                  </div>
                </template>

                <template #head(status)>
                  <div class="text-nowrap">
                    Status
                  </div>
                </template>

                <template #head(campaign)>
                  <div class="text-nowrap">
                    Campanha
                  </div>
                </template>

                <template #head(description)>
                  <div class="text-nowrap">
                    Descrição
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #cell(selected)="row">
                  <div
                    class="id-select"
                    @click.prevent="onClickSelect(row.item)"
                  >
                    <b-form-checkbox
                      v-if="row.item.chosen.campaign !== 'individual_analysis'"
                      v-model="row.item.selected"
                      :disabled="isSubmitting"
                    />
                  </div>
                </template>

                <template #cell(id)="row">
                  <div class="text-center">
                    <b-form-input
                      v-model="row.item.chosen.user_id"
                      :disabled="isSubmitting"
                    />
                    <b-button
                      v-if="$can('read', 'Users') && row.item.chosen.user_id !== ''"
                      variant="flat-primary"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.chosen.user_id } }"
                      title="Editar"
                      target="_blank"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>

                <template #cell(campaign)="row">
                  <b-form-group
                    label="Tag"
                  >
                    <b-form-select
                      v-if="row.item.tags.length > 1"
                      v-model="row.item.chosen.tag"
                      :disabled="isSubmitting"
                      :options="row.item.tags.map(m => ({ text: m, value: m, }))"
                      class="ml-1"
                      @change="onChangeTag(row.item)"
                    />
                    <span
                      v-else
                      class="ml-1"
                    >
                      {{ row.item.chosen.tag }}
                    </span>
                  </b-form-group>

                  <b-form-group
                    label="Ação / Campanha"
                  >
                    <b-form-select
                      v-model="row.item.chosen.campaign"
                      :disabled="isSubmitting"
                      :options="campaigns"
                      class="ml-1"
                    />
                  </b-form-group>
                </template>

                <template #cell(description)="row">
                  <h4
                    v-if="row.item.error.length > 0"
                    class="text-danger mb-2 d-inline-block"
                  >
                    <feather-icon
                      icon="AlertOctagonIcon"
                      size="18"
                      class="mr-1"
                      alt="Arquivo importado"
                    />{{ row.item.error }}
                    <br>
                  </h4>
                  <div class="mb-2">
                    <span title="Nome do arquivo importado">
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="mr-1"
                        alt="Arquivo importado"
                      />{{ row.item.name }}
                    </span>
                    <br>
                    <span
                      title="Telefone do arquivo importado"
                      class="ml-2 mb-2"
                    >
                      {{ row.item.phone }}
                    </span><br>
                    <span
                      title="E-mail do arquivo importado"
                      class="ml-2"
                    >
                      {{ row.item.email }}
                    </span>
                  </div>
                  <div
                    v-for="user in row.item.users"
                    :key="user.id"
                  >
                    <div
                      class="cursor-pointer mb-1"
                      @click="onChangeUser(row.item, user)"
                    >
                      <span>
                        <feather-icon
                          icon="GlobeIcon"
                          size="18"
                          :class="{ 'mr-1': 1 }"
                        />
                      </span>
                      <span
                        title="Usuário encontrado no site"
                        :class="{ 'mr-1': 1, 'text-info': user.found_in === 'users.id' }"
                      >
                        {{ user.id }}
                      </span>
                      <br>
                      <span
                        title="Usuário encontrado no site"
                      >
                        {{ user.name }}
                      </span>
                      <br>
                      <span
                        title="E-mail encontrado no site"
                        :class="{ 'mr-1': 1, 'text-info': user.found_in === 'users.email' }"
                      >
                        {{ user.all_emails.split(',').join(', ') }}
                      </span>
                      <br>
                      <span
                        title="Telefone encontrado no site"
                        :class="{ 'mr-1': 1, 'text-info': user.found_in === 'users.phone' }"
                      >
                        {{ user.all_phones.split(',').join(', ') }}
                      </span>
                      <br>
                      <span
                        title="Status"
                      >
                        Status: {{ $t(`users.columns.status.${user.status}`) }}
                      </span>
                    </div>
                  </div>
                </template>

                <template #cell(status)="row">
                  {{ $t(`users.columns.status.${row.item.status}`) }} <span v-if="row.item.status !== 'basic' && row.item.status !== 'review'"><br>{{ row.item.plan_group_name }} {{ row.item.payment_interval ? ' - ' + $t(`financial.columns.payment_interval.${row.item.payment_interval}`) : '' }}</span>
                </template>

                <template #cell(allEmails)="row">
                  {{ row.item.allEmails.join('\n') }}
                </template>

                <template #cell(allPhones)="row">
                  {{ row.item.allPhones.join('\n') }}
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-dropdown
                      v-if="$can('read', 'Users')"
                      title="Ações com o usuário"
                      variant="flat-primary"
                    >
                      <b-dropdown-item-button
                        @click="() => handleAssignKanbanDropDown(row.item)"
                      >
                        Atribuir Kanban
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        @click="() => handleInvoiceDropDown(row.item)"
                      >
                        Criar ticket de nota fiscal
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div
      class="btn-scroll-to-top text-right show"
    >
      <b-button
        variant="primary"
        class="mr-1"
        :disabled="isSubmitting || selectedUsers.length === 0"
        @click="handleProcessChosen"
      >
        <b-spinner
          v-if="isSubmitting "
          small
        />
        Processar Selecionados
      </b-button>

      <b-button
        variant="primary"
        :disabled="isSubmitting || selectedUsers.length === 0"
        @click="handleAssignKanban(selectedUsers)"
      >
        <b-spinner
          v-if="isSubmitting"
          small
        />
        Atribuir Kanban aos Selecionados
      </b-button>
    </div>

    <b-modal
      ref="move-card-modal"
      hide-footer
    >
      <move-card :data="cardData" />
    </b-modal>

    <b-modal
      ref="assign-kanban-modal"
      hide-footer
      size="lg"
      title="Atribuir Kanban"
      :no-close-on-backdrop="preventCloseModal"
      :no-close-on-esc="preventCloseModal"
      :hide-header-close="preventCloseModal"
    >
      <modal-assign-kanban
        :data="dataAssignKanban"
        @prevent-close-modal="changePreventCloseModal"
        @hide="hideAssignKanbanModal"
        @force-data-assign-kanban="handleAssignKanban"
        @assigned-users="handleAssignedUsers"
      />
    </b-modal>

  </section>
</template>

<style lang="scss" scoped>
  .btn.disabled {
    background-color: #f38493!important;
    border-color: #f38493!important;
    opacity: 1;
  }

  .btn-scroll-to-top {
    position: fixed;
    bottom: 5%;
    right: 80px;
    z-index: 99;

    opacity: 0;
    // transform: translateY(100px);
    transition: all .5s ease;

    &.show {
      opacity: 1;
      // transform: translateY(0)
    }
  }
</style>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import ChatFollowService from '@/services/chatFollowService'
import IssueService from '@/services/issueService'
import { formatDateTimeDbToView } from '@/utils/helpers'
import {
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BSpinner,
  BButton,
  BCard,
  BBreadcrumb,
  BDropdown,
  BDropdownItemButton,
} from 'bootstrap-vue'
import toast from '@/mixins/toast'
import ModalAssignKanban from '../active-users/ModalAssignKanban.vue'
import MoveCard from '../../kanban/MoveCard.vue'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormCheckbox,
    BFormFile,
    BFormSelect,
    BFormGroup,
    BSpinner,
    BButton,
    BBreadcrumb,
    BDropdown,
    BDropdownItemButton,
    ModalAssignKanban,
    MoveCard,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      userService: null,
      chatFollowService: null,
      isLoading: false,
      isSubmitted: true,
      isSubmitting: false,
      selectedUserIds: [],
      file: null,
      cardData: {},
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Acompanhamento pós Chat',
          active: true,
        },
      ],

      chosen: [],
      pages: 1,
      currentPage: 1,
      results: [],
      actionsList: [],
      result_fields: [],
      processVisitorIdAfter: 0,
      lastVisitorId: 0,
      settings: {},
      campaigns: [
        {
          text: 'Tickets',
          options: [
            {
              text: 'Ticket',
              value: 'ticket',
            },
            {
              text: 'Ticket de Nota Fiscal',
              value: 'ticket_nf',
            },
            {
              text: 'Ticket de Cancelar Cadastro',
              value: 'ticket_register_cancel',
            },
            {
              text: 'Ticket de Cancelar Assinatura',
              value: 'ticket_subscription_cancel',
            },
          ],
        },
        {
          text: 'Outros',
          options: [
            {
              text: 'Análise individual',
              value: 'individual_analysis',
            },
            {
              text: 'Aguardar / Nada a Fazer',
              value: 'await',
            },
          ],
        },
        {
          text: 'Usuário',
          options: [
            // {
            //   text: 'Cancelar Cadastro',
            //   value: 'user.new_lead',
            // },
            // {
            //   text: 'Cancelar Assinatura',
            //   value: 'user.new_lead',
            // },
            // {
            //   text: 'Bloquear Cadastro',
            //   value: 'user.new_lead',
            // },
            {
              text: 'Virar Status Lead - Empresa',
              value: 'user.lead_company',
            },
            {
              text: 'Virar Status Lead - Representante',
              value: 'user.lead_representative',
            },
          ],
        },
        {
          text: 'Mailchimp',
          options: [
            {
              text: 'Atualizar',
              value: 'mailchimp.update',
            },
            {
              text: 'Busca',
              value: 'mailchimp.search',
            },
            {
              text: 'Duplo',
              value: 'mailchimp.double',
            },
            {
              text: 'Gratuito',
              value: 'mailchimp.free',
            },
            {
              text: 'Login',
              value: 'mailchimp.login',
            },
            {
              text: 'Mensagem',
              value: 'mailchimp.message',
            },
            {
              text: 'Monitoramento',
              value: 'mailchimp.monitoring',
            },
            {
              text: 'Reativação',
              value: 'mailchimp.reactivation',
            },
            {
              text: 'Sugestão',
              value: 'mailchimp.suggestion',
            },
          ],
        },
      ],
      formatDateTimeDbToView,
      preventCloseModal: false,
      dataAssignKanban: null,
    }
  },
  computed: {
    selectedUsers() {
      return this.results.filter(f => f.selected && f.chosen.campaign !== 'individual_analysis').map(m => ({
        id: m.chosen.user_id,
        name: m.name,
      }))
    },
  },

  watch: {
    currentPage() {
      this.getUsers()
    },
  },

  created() {
    this.userService = new UserService()
    this.chatFollowService = new ChatFollowService()

    this.result_fields = [
      {
        key: 'selected', sortable: false, thStyle: 'width: 20px', tdClass: 'no-padding',
      },
      { key: 'id', sortable: false, thStyle: 'width: 150px' },
      { key: 'description', sortable: false },
      { key: 'campaign', sortable: false },
      { key: 'actions', sortable: false, thStyle: 'width: 180px' },
    ]

    this.getSettings()
  },

  methods: {
    reset() {
      this.results = []
      this.processVisitorIdAfter = this.lastVisitorId
      this.file = null
      this.isSubmitted = false
    },

    onClickSelect(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
    },

    onChangeTag(item) {
      const user = item.users.find(f => f.id === item.chosen.user_id)
      // eslint-disable-next-line no-param-reassign
      item.chosen.campaign = this.getCampaign(item.chosen.tag, user)
    },

    onChangeUser(item, user) {
      // eslint-disable-next-line no-param-reassign
      item.chosen.user_id = user.id
      // eslint-disable-next-line no-param-reassign
      item.chosen.campaign = this.getCampaign(item.chosen.tag, user)
    },

    getSettings() {
      this.chatFollowService.getSettings().then(response => {
        if (response.data !== null) {
          this.settings = response.data.data

          this.processVisitorIdAfter = this.settings.chat_follow_last_visitor_id
          this.lastVisitorId = this.settings.chat_follow_last_visitor_id
        }
      })
    },

    dynamicSortMultiple(props) {
      return (obj1, obj2) => {
        let i = 0
        let result = 0
        const numberOfProperties = props.length
        while (result === 0 && i < numberOfProperties) {
          result = this.dynamicSort(props[i])(obj1, obj2)
          i += 1
        }
        return result
      }
    },

    dynamicSort(props) {
      let sortOrder = 1
      let property = props
      if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
      }
      // eslint-disable-next-line no-unused-vars
      return (a, b) => {
        // eslint-disable-next-line no-nested-ternary,no-eval
        const result = (eval(`a.${property}`) < eval(`b.${property}`)) ? -1 : (eval(`a.${property}`) > eval(`b.${property}`)) ? 1 : 0
        return result * sortOrder
      }
    },

    getUsers() {
      if (this.isLoading) {
        return false
      }
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('process-visitor-id-after', this.processVisitorIdAfter)

      this.isSubmitted = true
      this.isLoading = true
      this.chatFollowService.sendFile(formData).then(response => {
        if (response.data !== null) {
          this.actionsList = response.data.data['chat-follow']['actions-list']
          this.results = response.data.data['chat-follow'].users.map(m => ({
            ...m,
            selected: false,
            error: '',
            chosen: {
              user_id: m.users.length ? m.users[0].id : '',
              tag: m.tags[0],
              campaign: this.getCampaign(m.tags[0], m.users[0] ?? null),
            },
          }))

          this.results.sort(this.dynamicSortMultiple(['chosen.campaign']))
        } else {
          this.toastDanger('Ops!', 'Nenhum resultado encontrado com os termos pesquisados')
        }
      }).catch(() => {
        this.toastDanger('Erro!', 'Aconteceu um erro ao enviar o arquivo. Por favor, tente novamente')
      }).finally(() => {
        this.isLoading = false
      })

      return true
    },

    getCampaign(tag, user) {
      if (!this.actionsList[tag]) {
        return this.actionsList.descarte.default
      }

      if (!user || !user.status) {
        return this.actionsList[tag].default
      }

      if (this.actionsList[tag][user.status]) {
        if (typeof this.actionsList[tag][user.status] === 'object') {
          if (this.actionsList[tag][user.status][`${user.situation}.${user.plan_type}`]) {
            return this.actionsList[tag][user.status][`${user.situation}.${user.plan_type}`]
          }
          return this.actionsList[tag][user.status][`${user.situation}`]
        }

        return this.actionsList[tag][user.status]
      }

      return this.actionsList[tag].default
    },

    openAssignKanbanModal() {
      this.$refs['assign-kanban-modal'].show()
    },

    hideAssignKanbanModal() {
      this.$refs['assign-kanban-modal'].hide()
    },

    changePreventCloseModal(event) {
      this.preventCloseModal = event
    },

    handleProcessChosen() {
      const total = this.results.filter(f => f.selected).length
      this.$swal({
        title: `Processar os ${total} usuários selecionados?`,
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, processar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.postProcessChosen()
        }
      })
    },

    postProcessChosen() {
      const formData = new FormData()

      const data = {
        users: [],
      }

      this.isSubmitting = true
      data.users = this.results.filter(f => (f.selected))
      data.users = data.users.map(m => ({
        visitor_id: m.visitor_id,
        name: m.name,
        email: m.email,
        user_id: m.chosen.user_id,
        destination: m.chosen.campaign,
      }))
      formData.append('data', data)
      this.chatFollowService.postProcessChosen(data).then(response => {
        if (response.status === 200) {
          response.data.data.forEach(item => {
            if (item.success) {
              this.results = this.results.filter(f => f.visitor_id !== item.visitor_id)
            } else {
              const visitor = this.results.find(f => f.visitor_id === item.visitor_id)
              if (visitor) {
                visitor.error = item.message
              }
            }
          })
        }
      }).finally(() => {
        this.isSubmitting = false
      })
    },

    handleAssignedUsers(data) {
      if (data) {
        this.results = this.results.filter(f => data.filter(f2 => f2.id === f.chosen.user_id).length === 0)
      }
    },

    handleInvoiceDropDown(data) {
      this.isSubmitting = true

      IssueService.store({
        title: 'Nota Fiscal',
        content: 'Nota fiscal solicitada pelo chat',
        to_all_from: 'management_processes',
        user_id: data.chosen.user_id,
        status: 'open',
        tags: ['invoice'],
      }).then(data).then(() => {
        this.toastSuccess('Sucesso!', 'Ticket criado!')
      }).finally(() => {
        this.isSubmitting = false
      })
    },

    handleAssignKanbanDropDown(data) {
      this.handleAssignKanban([{
        id: data.chosen.user_id,
        name: data.name,
      }])
    },

    handleAssignKanban(data) {
      this.dataAssignKanban = data
      this.openAssignKanbanModal()
    },

    openMoveCard(data) {
      this.cardData = data
      this.$refs['move-card-modal'].show()
    },

    toggleAll(e) {
      this.results = this.results.map(m => ({
        ...m,
        selected: e,
      }))
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .no-padding {
    padding: 0px!important;
  }

  .id-select {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
