/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class ChatFollowService {
  list(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/users?page=${page}`, params)
  }

  // eslint-disable-next-line class-methods-use-this
  sendFile(formData) {
    return useJwt.post('/chat-follow/import', {}, formData)
  }

  // eslint-disable-next-line class-methods-use-this
  getSettings() {
    return useJwt.get('/chat-follow/settings', {}, {})
  }

  // eslint-disable-next-line class-methods-use-this
  postProcessChosen(formData) {
    return useJwt.post('/chat-follow/process-chosen', {}, formData)
  }
}
