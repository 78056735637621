<template>
  <div>
    <b-alert
      variant="warning"
      show
      class="
        p-1
      "
    >
      <p>
        Apenas usuários que não estão em algum Kanban serão atribuídos.
      </p>
    </b-alert>
    <validation-observer ref="rulesAssignTo">
      <b-form>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                label="Atribuir a"
                label-for="assign_to"
              >
                <b-form-select
                  id="assign_to"
                  v-model="assignTo"
                  :options="selectUserRoles"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-form-group
              label="Consultor"
              label-for="manager"
            >
              <b-form-select
                id="manager"
                v-model="manager"
                :options="selectedRoleManagersOptions"
              />
            </b-form-group>
            <b-form-checkbox
              v-model="force"
              switch
            >
              Forçar atribuição
            </b-form-checkbox>
            <b-list-group
              class="mt-1"
            >
              <b-list-group-item active>
                Usuários:
              </b-list-group-item>
              <b-list-group-item
                v-for="(user) in data"
                :key="user.id"
                class="d-flex justify-content-between align-items-center"
              >
                {{ user.name }} ({{ user.id }})
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col
            v-if="assignTo === 'success'"
            cols="6"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                label="Origem"
                label-for="success_subscriber_origin"
              >
                <b-form-select
                  id="success_subscriber_origin"
                  v-model="successSubscriberOrigin"
                  :options="roleSuccessSubscriberOrigins"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              rules="required|min:10"
            >
              <b-form-group
                label="Descrição"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  rows="6"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="assignTo === 'sales'"
            cols="6"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                label="Origem do Lead"
                label-for="sales_lead_origin"
              >
                <b-form-select
                  id="sales_lead_origin"
                  v-model="salesLeadOrigin"
                  :options="salesLeadOriginOptions"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              rules="required|min:10"
            >
              <b-form-group
                label="Descrição"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  rows="6"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right mt-1">
            <b-button
              variant="primary"
              :disabled="isLoading"
              @click="handleActionAssignTo"
            >
              <span v-if="multipleData">
                Atribuir
                <strong>
                  {{ data.length }}
                </strong>
                usuários
              </span>
              <span v-else>
                Atribuir usuário
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal
      ref="assign-kanban-modal-force"
      hide-footer
      size="lg"
      title="Forçar atribuição no Kanban"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      Os usuários a seguir não foram atribuídos:
      <b-list-group
        class="mt-1"
      >
        <b-list-group-item
          v-for="(user) in usersNotAssigned"
          :key="user.id"
          class="d-flex justify-content-between align-items-center"
        >
          <b-row>
            <b-col cols="3">
              {{ user.name }} ({{ user.id }})
            </b-col>

            <b-col cols="9">
              <b-badge
                variant="primary"
                pill
              >
                {{ $t(`roles.${user.kanban.role}`) }}->{{ $t(`kanban.column.${user.kanban.column}`) }}
              </b-badge>
              <!-- eslint-disable vue/no-v-html -->
              <small
                class="d-block"
                v-html="user.reason.split('\n').join('<BR>')"
              />
              <!-- eslint-enable vue/no-v-html -->
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>

      <b-row>
        <b-col class="text-right mt-1">
          <b-button
            class="mr-1"
            @click="forceAssignTo()"
          >
            Forçar atribuição
          </b-button>

          <b-button
            variant="primary"
            @click="hide()"
          >
            Ok
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BAlert,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  userRoles,
  salesLeadOriginOptions,
  roleSuccessSubscriberOrigins,
} from '@/utils/options'
import UserService from '@/services/userService'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      required,
      ToastificationContent,
      isLoading: false,
      kanbanService: null,
      description: '',
      force: false,

      assignTo: null,
      userRoles,

      manager: null,

      usersNotAssigned: [],

      salesLeadOrigin: null,
      successSubscriberOrigin: null,
      salesLeadOriginOptions: salesLeadOriginOptions.filter(f => f.assignable),
      roleSuccessSubscriberOrigins,
    }
  },
  computed: {
    selectUserRoles() {
      const r = this.userRoles.filter(f => (['sales', 'mentoring', 'relationship', 'success', 'service_qualification'].includes(f.value))).map(m => {
        if (m.value === 'success') {
          return {
            value: m.value,
            text: 'Canal VIP - Assinantes',
          }
        } if (m.value === 'relationship') {
          return {
            value: m.value,
            text: 'Canal VIP - Onboarding',
          }
        }

        return m
      })
      r.sort((a, b) => a.text.localeCompare(b.text))
      return r
    },
    variant() {
      return document.body.classList.contains('dark-layout') ? 'dark' : undefined
    },
    multipleData() {
      return (this.data || []).length > 1
    },
    selectedRoleManagersOptions() {
      return [
        {
          text: '-- Sortear --',
          value: null,
        },
        ...(JSON.parse(localStorage.getItem('managers')) || []).filter(f => !f.deleted_at).reduce((managers, manager) => (
          (
            manager.permission_type === this.assignTo
              || (manager.permission_type === 'service_qualification' && this.assignTo === 'success')
              || (manager.permission_type === 'service_qualification' && this.assignTo === 'relationship')
              || (manager.permission_type === 'mentoring' && this.assignTo === 'success')
          ) ? [
              ...managers,
              {
                value: manager.id,
                text: `${this.$t(`users.permission_type.${manager.permission_type}`)} - ${manager.name}`,
              },
            ] : managers
        ), []).sort((a, b) => (a.text.localeCompare(b.text))),
      ]
    },
  },
  watch: {
    assignTo() {
      this.manager = this.selectedRoleManagersOptions[0].value
    },
  },
  async created() {
    this.userService = new UserService()
  },
  methods: {
    toast(title, text, success) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: success ? 'EditIcon' : 'BellIcon',
          variant: success ? 'info' : 'danger',
        },
      },
      {
        position: 'top-center',
      })
    },
    handleActionAssignTo() {
      this.$refs.rulesAssignTo.validate().then(success => {
        if (success) {
          this.handleAssignTo()
        } else {
          this.toast('Erro de validação', 'Preencha corretamente os campos', false)
        }
      })
    },

    hide() {
      this.$refs['assign-kanban-modal-force'].hide()
    },

    forceAssignTo() {
      this.$refs['assign-kanban-modal-force'].hide()
      this.force = true
      this.$emit('force-data-assign-kanban', this.usersNotAssigned)
    },

    handleAssignTo() {
      this.isLoading = true
      this.$emit('prevent-close-modal', true)

      const data = {
        user_ids: this.data.map(user => user.id),
        assign_to: this.assignTo,
        sales_lead_origin: this.salesLeadOrigin,
        success_subscriber_origin: this.successSubscriberOrigin,
        description: this.description,
        manager: this.manager,
      }

      this.userService.assignTo(data, this.force).then(response => {
        if (response.status === 200) {
          const responseUsers = response.data.data.users
          const usersAssigned = this.data.filter(f => responseUsers.find(u => f.id === u.id).resultAssignTo)
          this.$emit('assigned-users', usersAssigned)
          this.usersNotAssigned = this.data.filter(f => !responseUsers.find(u => f.id === u.id).resultAssignTo)
            .map(m => ({
              ...m,
              kanban: m.kanban ? m.kanban : responseUsers.find(u => m.id === u.id).kanban,
              reason: responseUsers.find(u => m.id === u.id).kanban.reason,
            }))
          if (this.usersNotAssigned.length > 0) {
            this.$refs['assign-kanban-modal-force'].show()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Atribuído${this.multipleData ? 's' : ''} com sucesso!`,
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.$emit('hide')
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro!',
              text: 'Ops, algo deu errado!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: 'Ops, algo deu errado!',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isLoading = false
        this.$emit('prevent-close-modal', false)
      })
    },
  },
}
</script>

<style>

</style>
